@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
    margin: 0;
    padding: 0;
    font-family: 'Work Sans', sans-serif;
}

.MainSec {
    width: 100%;
    height: auto;
    background-color: #fff;
    padding: 50px 60px;
    overflow: hidden;
    position: relative;
}

.MainSec .LineGra {
    position: absolute;
    width: auto;
    max-height: 1070px;
    top: -60px;
    right: -130px;
}

.LandPageContainer {
    width: 100%;
    max-width: 1440px;
    margin: auto;
    position: relative;
}

.HeadLogoSec {
    width: 100%;
    margin-bottom: 70px;
}

.HeadLogoSec .LogoText {
    font-family: 'Rubik', sans-serif;
    color: #05051E;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    text-align: left;
    margin: 0;
}

.HeadTextSec {
    width: 100%;
    max-width: 650px;
    margin-bottom: 70px;
}

.HeadTextSec .HeadTitleText {
    color: #05051E;
    font-size: 80px;
    font-weight: 800;
    line-height: 105%;
    letter-spacing: -0.8px;
    margin-bottom: 32px;
    text-align: left;
}

.HeadTextSec .HeadConText {
    width: 100%;
    max-width: 680px;
    color: #696978;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 155%;
    letter-spacing: -0.18px;
    text-align: left;
    margin: 0;
}

.HeadFormSec {
    width: 100%;
    max-width: 680px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;
}

.HeadFormSec input {
    width: calc(100% - 90px);
    height: 64px;
    padding: 10px 24px;
    border-radius: 64px;
    background: #fff;
    border: 1.5px solid #E4E5EB;
    color: #333;
}

.HeadFormSec input:focus {
    outline: 0px solid #F2CA8E;
    border: 1.5px solid #F2CA8E;
    box-shadow: none;
}

.HeadFormSec .HeadFormBU {
    width: 64px;
    height: 64px;
    border: none;
    border-radius: 50%;
    background-color: #F2CA8E;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.HeadFormSec .HeadFormBU::before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,.5);
    top: 0;
    left: -100%;
    position: absolute;
    background: linear-gradient(
        120deg,
        rgba(255,255,255, 0) 30%,
        rgba(255,255,255, .8),
        rgba(255,255,255, 0) 70%
    );
    transition: all .5s ease-in-out;
}

.HeadFormSec .HeadFormBU:hover::before {
    left: 100%;
}

.HeadFormSec .HeadFormBU svg {
    width: 32px;
    height: 32px;
    color: #05051E;
    position: relative;
}

.HeadIMGSec {
    width: 100%;
    position: relative;
}

.HeadIMGSec img.Banner {
    width: 100%;
    height: 600px;
    border-radius: 24px;
    position: relative;
    z-index: 1;
    object-fit: cover;
    object-position: center;
}

.HeadIMGSec img.BannerGra {
    width: auto;
    height: 300px;
    position: absolute;
    right: -70px;
    bottom: -180px;
}

.OfferSec {
    width: 100%;
    margin-top: 120px;
}

.BlogTileTT {
    color: #05051E;
    font-size: 56px;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: -0.56px;
    margin: 0 0 50px 0;
}

.OfferRow {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -25px;
}

.OfferDivSec {
    width: 50%;
    padding: 25px;
}

.OfferDivSecUnder {
    width: 100%;
}

.OfferDivSec .Img {
    width: 100%;
    height: 500px;
    object-fit: cover;
    object-position: center;
    border-radius: 24px;
    margin-bottom: 55px;
}

.OfferCardTitle {
    color: #05051E;
    font-size: 32px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.32px;
    text-align: left;
    margin: 0 0 24px 0;
}

.OfferCardTT {
    color: #696978;
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.18px;
    text-align: left;
    margin: 0 0 10px 0;
}

.ValueSec {
    width: 100%;
    margin-top: 120px;
}

.ValueTileTT {
    color: #05051E;
    font-size: 56px;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: -0.56px;
    margin: 0 0 30px 0;
}

.ValueTileSmallTT {
    color: #696978;
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.18px;
    margin: 0 0 48px 0;
}

.ValueRow {
    display: flex;
    flex-wrap: wrap;
}

.ValueCard {
    width: 33.33%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
}

.ValueCard .Img {
    max-width: 70px;
    max-height: 56px;
    margin: 0 0 40px 0;
}

.ValueCardTT {
    color: #05051E;
    font-size: 24px;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.24px;
    margin: 0;
    max-width: 260px;
}

.ValueCard:nth-child(1) {
    border-right: 1px solid #f2ca8e99;
    border-bottom: 1px solid #f2ca8e99;
}

.ValueCard:nth-child(2) {
    border-right: 1px solid #f2ca8e99;
    border-bottom: 1px solid #f2ca8e99;
}

.ValueCard:nth-child(3) {
    border-bottom: 1px solid #f2ca8e99;
}

.ValueCard:nth-child(4) {
    border-right: 1px solid #f2ca8e99;
}

.ValueCard:nth-child(5) {
    border-right: 1px solid #f2ca8e99;
}

.SubscribeSec {
    margin: 120px 0 40px 0;
    border-radius: 24px;
    background: #F2CA8E;
    position: relative;
    overflow: hidden;
    padding: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.SubscribeSec .LineGraB {
    position: absolute;
    width: auto;
    max-height: 353px;
    right: 0;
    bottom: 0;
}

.SubscribeSecUnder {
    width: 100%;
    max-width: 580px;
    position: relative;
}

.SubscribeSecTitle {
    color: #05051E;
    font-size: 56px;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: -0.56px;
    margin: 0 0 24px 0;
}

.SubscribeSecSMTitle {
    color: #343441;
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.18px;
    margin: 0;
}

.SubscribeFormSec {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 50px auto 0 auto;
}

.SubscribeFormSec input {
    width: calc(100% - 180px);
    height: 64px;
    padding: 10px 24px;
    border-radius: 64px;
    border: 1px solid #DAB683;
    background-color: #F2CA8E;
}

.SubscribeFormSec input:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #05051E;
}

.SubscribeFormSec .SubmitBU {
    width: auto;
    height: 64px;
    padding: 10px 32px;
    border-radius: 64px;
    border: 1px solid #DAB683;
    background: #05051E;
    color: #CFD1E3;
    font-size: 18px;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.18px;
    position: relative;
    overflow: hidden;
}

.SubscribeFormSec .SubmitBU::before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,.5);
    top: 0;
    left: -100%;
    position: absolute;
    background: linear-gradient(
        110deg,
        rgba(255,255,255, 0) 30%,
        rgba(255,255,255, .5),
        rgba(255,255,255, 0) 70%
    );
    transition: all .5s ease-in-out;
}

.SubscribeFormSec .SubmitBU:hover::before {
    left: 100%;
}

.FooterSec {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.FooterLogo {
    width: 100%;
    color: #05051E;
    font-size: 20px;
    font-weight: 500;
    line-height: 110%;
    margin: 0 0 16px 0;
}

.FooterTT {
    width: 100%;
    color: #696978;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    margin: 0;
}

.ErrorM {
    font-size: 14px;
    font-weight: 400;
    color: #f40;
    padding: 5px 0 0 25px;
}

@media (min-width:360px) {
    .OfferDivSec .Img {
        height: 220px;
        margin-bottom: 30px;
    }
    .HeadLogoSec .LogoText {
        font-size: 20px;
    }
    .HeadTextSec .HeadTitleText {
        font-size: 42px;
    }    
    .HeadTextSec .HeadConText {
        font-size: 14px;
    }
    .HeadIMGSec img.Banner {
        height: 220px;
    }
    .ValueCard {
        width: 100%;
        height: 220px;
    }
    .OfferCardTitle {
        font-size: 22px;
    }    
    .OfferCardTT {
        font-size: 15px;
    }
    .ValueCard:nth-child(1) {
        border-right: 0px solid #f2ca8e99;
        border-bottom: 1px solid #f2ca8e99;
    }    
    .ValueCard:nth-child(2) {
        border-right: 0px solid #f2ca8e99;
        border-bottom: 1px solid #f2ca8e99;
    }    
    .ValueCard:nth-child(3) {
        border-right: 0px solid #f2ca8e99;
        border-bottom: 1px solid #f2ca8e99;
    }    
    .ValueCard:nth-child(4) {
        border-right: 0px solid #f2ca8e99;
        border-bottom: 1px solid #f2ca8e99;
    }    
    .ValueCard:nth-child(5) {
        border-right: 0px solid #f2ca8e99;
        border-bottom: 1px solid #f2ca8e99;
    }
    .ValueCardTT {
        font-size: 20px;
        max-width: 260px;
    }
    .SubscribeSecTitle {
        font-size: 38px;
    }
    .SubscribeSec {
        padding: 30px;
        margin: 50px 0 40px 0;
    }
    .SubscribeSec .LineGraB {
        max-height: 380px;
        right: -450px;
    }
    .MainSec .LineGra {
        max-height: 680px;
        top: -60px;
        right: -300px;
    }
    .BlogTileTT,
    .ValueTileTT {
        font-size: 36px;
        margin-bottom: 20px;
    }
    .OfferDivSec {
        width: 100%;
        padding: 25px;
    }  
    .SubscribeSecSMTitle {
        font-size: 16px;
    }  
    .SubscribeFormSec {
        width: 100%;
        margin: 30px auto 0 auto;
    }
    .SubscribeFormSec input {
        width: 100%;
    }
    .SubscribeFormSec .SubmitBU {
        width: 100%;
        margin-top: 15px;
    }
    .MainSec {
        padding: 20px 20px;
    }
    .HeadLogoSec {
        margin-bottom: 40px;
    }
}

@media (min-width:480px) {}

@media (min-width:576px) {
    .OfferDivSec .Img {
        height: 280px;
        margin-bottom: 40px;
    }
    .HeadLogoSec .LogoText {
        font-size: 22px;
    }
    .HeadTextSec .HeadTitleText {
        font-size: 55px;
    }    
    .HeadTextSec .HeadConText {
        font-size: 16px;
    }
    .HeadIMGSec img.Banner {
        height: 280px;
    }
    .ValueCard {
        width: 50%;
        height: 300px;
    }
    .OfferCardTitle {
        font-size: 28px;
    }    
    .OfferCardTT {
        font-size: 16px;
    }
    .ValueCard:nth-child(1) {
        border-right: 1px solid #f2ca8e99;
        border-bottom: 1px solid #f2ca8e99;
    }    
    .ValueCard:nth-child(2) {
        border-right: 0px solid #f2ca8e99;
        border-bottom: 1px solid #f2ca8e99;
    }    
    .ValueCard:nth-child(3) {
        border-right: 1px solid #f2ca8e99;
        border-bottom: 1px solid #f2ca8e99;
    }    
    .ValueCard:nth-child(4) {
        border-right: 0px solid #f2ca8e99;
        border-bottom: 1px solid #f2ca8e99;
    }    
    .ValueCard:nth-child(5) {
        border-right: 1px solid #f2ca8e99;
        border-bottom: 0px solid #f2ca8e99;
    }
    .ValueCardTT {
        font-size: 20px;
        max-width: 200px;
    }
    .SubscribeSecTitle {
        font-size: 38px;
    }
    .SubscribeSec {
        padding: 30px;
        margin: 80px 0 40px 0;
    }
    .SubscribeSec .LineGraB {
        max-height: 380px;
        right: -120px;
    }
    .MainSec .LineGra {
        max-height: 800px;
        top: -60px;
        right: -300px;
    }
    .BlogTileTT,
    .ValueTileTT {
        font-size: 42px;
        margin-bottom: 50px;
    }
    .OfferDivSec {
        width: 100%;
        padding: 25px;
    }  
    .SubscribeSecSMTitle {
        font-size: 16px;
    }  
    .SubscribeFormSec {
        width: 100%;
        margin: 30px auto 0 auto;
    }
    .SubscribeFormSec input {
        width: 100%;
    }
    .SubscribeFormSec .SubmitBU {
        width: 100%;
        margin-top: 15px;
    }
    .MainSec {
        padding: 30px 30px;
    }
    .HeadLogoSec {
        margin-bottom: 50px;
    }
}

@media (min-width:768px) {
    .OfferDivSec .Img {
        height: 280px;
    }
    .HeadLogoSec .LogoText {
        font-size: 22px;
    }
    .HeadTextSec .HeadTitleText {
        font-size: 60px;
    }    
    .HeadTextSec .HeadConText {
        font-size: 16px;
    }
    .HeadIMGSec img.Banner {
        height: 380px;
    }
    .ValueCard {
        width: 50%;
        height: 300px;
    }
    .OfferCardTitle {
        font-size: 28px;
    }    
    .OfferCardTT {
        font-size: 16px;
    }
    .ValueCard:nth-child(1) {
        border-right: 1px solid #f2ca8e99;
        border-bottom: 1px solid #f2ca8e99;
    }    
    .ValueCard:nth-child(2) {
        border-right: 0px solid #f2ca8e99;
        border-bottom: 1px solid #f2ca8e99;
    }    
    .ValueCard:nth-child(3) {
        border-right: 1px solid #f2ca8e99;
        border-bottom: 1px solid #f2ca8e99;
    }    
    .ValueCard:nth-child(4) {
        border-right: 0px solid #f2ca8e99;
        border-bottom: 1px solid #f2ca8e99;
    }    
    .ValueCard:nth-child(5) {
        border-right: 1px solid #f2ca8e99;
    }
    .ValueCardTT {
        font-size: 20px;
        max-width: 220px;
    }
    .SubscribeSecTitle {
        font-size: 48px;
    }
    .SubscribeSec {
        padding: 40px;
        margin: 120px 0 40px 0;
    }
    .SubscribeSec .LineGraB {
        max-height: 310px;
        right: -120px;
    }
    .MainSec .LineGra {
        max-height: 800px;
        top: -60px;
        right: -300px;
    }
    .BlogTileTT,
    .ValueTileTT {
        font-size: 56px;
    }
    .OfferDivSec {
        width: 50%;
        padding: 25px;
    }
    .SubscribeSecSMTitle {
        font-size: 18px;
    } 
    .SubscribeFormSec {
        width: 90%;
        margin: 50px auto 0 auto;
    }
    .SubscribeFormSec input {
        width: calc(100% - 180px);
    }
    .SubscribeFormSec .SubmitBU {
        width: auto;
        margin-top: 0;
    }
    .MainSec {
        padding: 40px 50px;
    }
}

@media (min-width:992px) {
    .OfferDivSec .Img {
        height: 350px;
        margin-bottom: 55px;
    }
    .HeadLogoSec .LogoText {
        font-size: 24px;
    }
    .HeadTextSec .HeadTitleText {
        font-size: 70px;
    }    
    .HeadTextSec .HeadConText {
        font-size: 16px;
    }
    .HeadIMGSec img.Banner {
        height: 500px;
    }
    .ValueCard {
        width: 33.33%;
        height: 300px;
    }
    .OfferCardTitle {
        font-size: 32px;
    }    
    .OfferCardTT {
        font-size: 18px;
    }
    .ValueCard:nth-child(1) {
        border-right: 1px solid #f2ca8e99;
        border-bottom: 1px solid #f2ca8e99;
    }    
    .ValueCard:nth-child(2) {
        border-right: 1px solid #f2ca8e99;
        border-bottom: 1px solid #f2ca8e99;
    }    
    .ValueCard:nth-child(3) {
        border-right: 0px solid #f2ca8e99;
        border-bottom: 1px solid #f2ca8e99;
    }    
    .ValueCard:nth-child(4) {
        border-right: 1px solid #f2ca8e99;
        border-bottom: 0px solid #f2ca8e99;
    }    
    .ValueCard:nth-child(5) {
        border-right: 1px solid #f2ca8e99;
    }
    .ValueCardTT {
        font-size: 24px;
        max-width: 260px;
    }
    .SubscribeSecTitle {
        font-size: 56px;
    }
    .SubscribeSec .LineGraB {
        max-height: 320px;
        right: -100px;
    }
    .MainSec .LineGra {
        max-height: 900px;
        top: -60px;
        right: -250px;
    }
    .MainSec {
        padding: 50px 60px;
    }
    .HeadLogoSec {
        margin-bottom: 70px;
    }
}

@media (min-width:1200px) {
    .OfferDivSec .Img {
        height: 400px;
    }
    .HeadLogoSec .LogoText {
        font-size: 28px;
    }
    .HeadTextSec .HeadTitleText {
        font-size: 80px;
    }    
    .HeadTextSec .HeadConText {
        font-size: 18px;
    }
    .HeadIMGSec img.Banner {
        height: 600px;
    }
    .ValueCard {
        height: 400px;
    }
    .SubscribeSec .LineGraB {
        max-height: 353px;
        right: 0;
    }
    .MainSec .LineGra {
        max-height: 1070px;
        top: -60px;
        right: -130px;
    }
}

@media (min-width:1400px) {
    .OfferDivSec .Img {
        height: 500px;
    }
}